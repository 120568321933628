import axios from '@/libs/axios'

export function reqGetTasks(payload) {
  return axios.get('api/tasks', { params: payload })
}
export function reqGetTrainingTask(id) {
  return axios.get(`api/trainings/${id}/task`)
}
export function reqGetChapterTask(id) {
  return axios.get(`api/chapters/${id}/task`)
}
export function reqGetTask(id) {
  return axios.get(`api/tasks/${id}`)
}

export function reqStartTaskOption(id) {
  return axios.post(`api/task-options/${id}/start`)
}
export function reqGetTaskOptionQuestions(id) {
  return axios.get(`api/task-options/${id}/questions`)
}
export function reqFinsihTaskOption(id) {
  return axios.post(`api/task-options/${id}/finish`)
}
export function reqSaveTaskOptionAnswer(id, payload) {
  return axios.post(`api/option-questions/${id}/save`, payload)
}

export function reqStartTaskEssay(id) {
  return axios.post(`api/task-essays/${id}/start`)
}
export function reqGetTaskEssayQuestions(id) {
  return axios.get(`api/task-essays/${id}/questions`)
}
export function reqFinsihTaskEssay(id) {
  return axios.post(`api/task-essays/${id}/finish`)
}
export function reqSaveTaskEssayAnswer(id, payload) {
  return axios.post(`api/essay-questions/${id}/save`, payload)
}
