<template>
  <div>
    <!-- Task Card Header -->
    <b-card
      v-if="!isLoading && task.id"
      class="quiz-overview-card mb-1 p-1"
    >
      <div class="d-flex">
        <div class="mr-1">
          <b-avatar
            rounded="lg"
            :variant="taskType(contentType).variant"
          >
            <i
              class="card-icon"
              :class="taskType(contentType).icon"
            />
          </b-avatar>
        </div>
        <div class="d-flex flex-column">
          <div class="d-flex justify-content-between">
            <div class="d-flex flex-column">
              <span class="text-muted">
                {{ taskType(contentType).title }}
              </span>
              <h6 class="font-weight-bolder small-gap-bottom">
                {{ task.name }}
              </h6>
            </div>
          </div>
        </div>
      </div>
      <b-row class="mt-2">
        <b-col md="4">
          <!-- Class Info -->
          <div v-if="quizStatus === 'isFinished'">
            <h6 class="font-weight-bolder mt-1 small-gap-bottom">
              Nilai
            </h6>
            <h2 :class="getPointClass(task.my_result.point)">
              {{ task.my_result.point }}
            </h2>
          </div>
          <div>
            <h6 class="font-weight-bolder mt-1 small-gap-bottom">
              Materi
            </h6>
            <span>
              {{ task.chapter.title }}
            </span>
          </div>

          <!-- Quiz Type-->
          <div>
            <h6 class="font-weight-bolder mt-1 small-gap-bottom">
              Tipe Tugas
            </h6>
            <span>
              {{ taskType(contentType).title.split(" : ").pop() }}
            </span>
          </div>

          <div>
            <h6 class="font-weight-bolder mt-1 small-gap-bottom">
              Jumlah Soal
            </h6>
            <span>
              {{ task.question_count }}
            </span>
          </div>

          <div>
            <h6 class="font-weight-bolder mt-1 small-gap-bottom">
              Batas Pengerjaan
            </h6>
            <span>{{ task.end_at | moment("dddd, Do MMM YYYY HH:mm") }} </span>
          </div>

          <div>
            <h6 class="font-weight-bolder mt-1 small-gap-bottom">
              Deskripsi
            </h6>
            <span>
              {{ task.description }}
            </span>
          </div>
        </b-col>
        <b-col>
          <!-- Exam Alert  -->
          <b-alert
            show
            variant="warning"
          >
            <div class="alert-body p-2">
              <div class="d-flex">
                <div class="mr-1">
                  <b-avatar
                    rounded="lg"
                    class="bg-white"
                  >
                    <i class="card-icon text-warning ti ti-alert-triangle" />
                  </b-avatar>
                </div>
                <div class="d-flex flex-column">
                  <h5 class="font-weight-bolder small-gap-bottom text-warning">
                    Perhatian
                  </h5>
                  <span class="font-weight-normal">
                    Sebelum memulai ujian, pastikan kamu sudah siap dan kerjakan
                    ujian secara mandiri, jangan bertanya kepada teman atau
                    orang lain. Awali dengan membaca soal dengan teliti dan
                    berdoa kepada yang maha kuasa agar diberi kemudahan dalam
                    mengerjakan soal.
                  </span>
                </div>
              </div>
            </div>
          </b-alert>

          <!-- Misc -->
          <div>
            <div class="d-flex justify-content-between small-gap-bottom">
              <h6 class="font-weight-bolder small-gap-bottom">
                Progress
              </h6>
            </div>
            <b-progress
              :value="taskProgress"
              max="100"
            />
            <ul class="regular-ul small-gap-top">
              <li>
                Lihat progress bar ini untuk melihat waktu yang sedang berjalan
              </li>
              <li>
                Kerjakan soal yang paling mudah terlebih dahulu, periksa kembali
                jawaban anda.
              </li>
            </ul>
          </div>

          <!-- Exam Step Info -->
          <div>
            <h6 class="font-weight-bolder mt-1 small-gap-bottom">
              Langkah Mengerjakan Tugas
            </h6>
            <ol class="regular-ul">
              <li>Klik “Konfirmasi kesediaan tugas.</li>
              <li>
                Kerjakan sesuai dengan pertanyaan yang ada dan pastikan jawaban
                sudah terisi dengan teliti.
              </li>
              <li>Jika masih ragu-ragu terhadap pilihan klik button tandai.</li>
              <li>Klik tombol “Selesaikan Tugas di akhir ujian.</li>
            </ol>
          </div>
        </b-col>

        <template v-if="task.question_count">
          <!-- Situational Button -->
          <!-- Is Not Started -->
          <b-col
            v-if="quizStatus === 'isNotStarted'"
            md="12"
            class="border-top pt-1 mt-1"
          >
            <!-- Accept term and condition -->
            <div class="d-flex justify-content-start small-gap-bottom mb-1">
              <b-form-checkbox
                v-model="isAcceptTerm"
                value="true"
              >
                Konfirmasi kesediaan mengikuti ujian.
              </b-form-checkbox>
            </div>

            <div class="d-flex justify-content-start">
              <b-button
                variant="outline-danger"
                :to="{
                  name: checkAuthorizeRole('trainings.show'),
                  params: {
                    trainingId: this.$route.params.trainingId,
                  },
                }"
              >
                Batal
              </b-button>
              <b-button
                variant="primary"
                class="ml-1"
                :disabled="!isAcceptTerm"
                @click="startTask()"
              >
                <span class="align-middle mr-1">Mulai Mengerjakan</span>
                <i class="align-middle ti ti-arrow-right" />
              </b-button>
            </div>
          </b-col>

          <!-- Is Started -->
          <b-col
            v-if="quizStatus === 'isStarted'"
            md="12"
            class="border-top pt-1 mt-1"
          >
            <div class="d-flex justify-content-start">
              <b-button
                variant="warning"
                class="ml-1"
                @click="startTask()"
              >
                <span class="align-middle mr-1">Lanjut Mengerjakan</span>
                <i class="align-middle ti ti-arrow-right" />
              </b-button>
            </div>
          </b-col>

          <b-col
            v-if="quizStatus === 'isWaitCorrection'"
            md="12"
            class="border-top pt-1 mt-1"
          >
            <div class="d-flex justify-content-start">
              <b-button
                variant="outline-info"
                class="ml-1"
                disabled
              >
                <span class="align-middle mr-1">Tugas Sedang DIkoreksii</span>
              </b-button>
            </div>
          </b-col>
          <!-- Is Unavailable -->
          <b-col
            v-if="quizStatus === 'isFinished'"
            md="12"
            class="border-top pt-1 mt-1"
          >
            <div class="d-flex justify-content-start">
              <b-button
                variant="outline-success"
                class="ml-1"
                disabled
              >
                <span class="align-middle mr-1">Tugas Telah Selesai</span>
              </b-button>
            </div>
          </b-col>
        </template>
      </b-row>
    </b-card>

    <div
      v-if="isLoading"
      class="d-flex flex-column align-items-center vertical-center"
    >
      <b-spinner variant="primary" />
      <span class="small-gap-top">Memuat Tugas</span>
    </div>
  </div>
</template>

<script>
// *Components
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BAlert,
  BAvatar,
  BMedia,
  BProgress,
  BFormCheckbox,
  BSpinner,
} from 'bootstrap-vue'

// *Utils
import { avatarText, taskType } from '@core/utils/filter'
import { checkAuthorizeRole } from '@/auth/utils'
import {
  reqGetChapterTask,
  reqStartTaskOption,
  reqStartTaskEssay,
} from '@/api/user/task'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BAlert,
    BAvatar,
    BMedia,
    BProgress,
    BFormCheckbox,
    BSpinner,
  },
  data() {
    return {
      // State
      isLoading: true,
      // Basic Data
      classroomId: this.$router.currentRoute.params.id,
      contentId: this.$router.currentRoute.params.contentId,
      taskId: this.$router.currentRoute.params.taskId,
      contentType: {},

      // Exam Data
      task: {},
      isAcceptTerm: false,
    }
  },
  beforeRouteEnter(to, from, next) {
    to.meta.breadcrumb[1].params = {
      trainingId: to.params.trainingId,
      chapterId: to.params.chapterId,
    }
    next()
  },
  computed: {
    taskProgress() {
      return (
        (this.task.answered_question_count / this.task.question_count) * 100
      )
    },
    quizStatus() {
      let status = 'isUnavailable'

      if (!this.task.my_result) {
        status = 'isNotStarted'
      } else {
        if (
          ['Belum Dikerjakan', 'Sedang Dikerjakan'].includes(
            this.task.my_result.status,
          )
        ) {
          status = 'isStarted'
        }
        if (
          this.task.my_result.status === 'Sedang Dikoreksi'
          || this.task.my_result.status === 'Sudah Dikerjakan'
        ) {
          status = 'isWaitCorrection'
        }
        if (this.task.my_result.status === 'Selesai') {
          status = 'isFinished'
        }
      }

      return status
    },
  },
  async mounted() {
    this.isLoading = true
    await reqGetChapterTask(this.$route.params.chapterId)
      .then(response => {
        this.task = response.data.data
        this.contentType = response.data.data.type

        this.isLoading = false
      })
      .catch(error => {
        this.isLoading = false
        this.$swal({
          title: 'Oops',
          text: error.response.data.message,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Oke',
          cancelButtonText: 'Batal',
        }).then(() => {
          this.$router.push({
            name: checkAuthorizeRole('trainings.show'),
            params: {
              trainingId: this.$route.params.trainingId
            },
          })
        })
      })
  },
  methods: {
    avatarText,
    taskType,
    checkAuthorizeRole,
    startTask() {
      if (this.contentType == 'Pilihan Ganda') {
        reqStartTaskOption(this.task.id).then(response => {
          this.$router.push({
            name: 'user-task-multiplechoice-questions.show',
            params: {
              trainingId: this.$route.params.trainingId,
              chapterId: this.$route.params.chapterId,
              taskId: this.task.id,
            },
          })
        })
      } else {
        reqStartTaskEssay(this.task.id).then(response => {
          this.$router.push({
            name: 'user-task-essay-questions.show',
            params: {
              trainingId: this.$route.params.trainingId,
              chapterId: this.$route.params.chapterId,
              taskId: this.task.id,
            },
          })
        })
      }
      localStorage.setItem('taskSession', JSON.stringify(this.task))
    },
    getPointClass(point) {
      if (point > 90) {
        return 'text-success'
      } if (point >= 70) {
        return 'text-info'
      } if (point >= 50) {
        return 'text-warning'
      } if (point < 50) {
        return 'text-danger'
      }
    },
  },
}
</script>

<style lang="scss">
.regular-icon {
  top: 0 !important;
  font-size: 1.2rem;
}

.card-icon {
  top: 0 !important;
  font-size: 1.5rem;
}

.card-icon-lg {
  top: 0 !important;
  font-size: 2.5rem;
}

.regular-ul {
  padding-left: 1.5rem;
}

.quiz-overview-card {
  li {
    line-height: 1.6rem;
  }
}
</style>
